import { faBandcamp } from "@fortawesome/free-brands-svg-icons";
import {
  faCheckCircle,
  faClock,
  faCoins,
  faHome,
  faLanguage,
  faTimesCircle,
  faToolbox,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Feature from "../../../components/Feature";

const shortInfo = [
  {
    label: "Duration",
    value: "",
    icon: <FontAwesomeIcon icon={faClock} />,
  },
  {
    label: "Travel Type",
    value: "Luxurious",
    icon: <FontAwesomeIcon icon={faToolbox} />,
  },
  {
    label: "Group Size",
    value: "A/C",
    icon: <FontAwesomeIcon icon={faBandcamp} />,
  },
  {
    label: "Languages",
    value: "English / Spanish",
    icon: <FontAwesomeIcon icon={faLanguage} />,
  },
  {
    label: "Starts From",
    value: "",
    icon: <FontAwesomeIcon icon={faCoins} />,
  },
  {
    label: "Accomodation",
    value: "Preferred",
    icon: <FontAwesomeIcon icon={faHome} />,
  },
];
const tableItems = [
  {
    label: "Destination",
    value: [""],
    icon: "",
  },
  {
    label: "Accommodation",
    value: ["5 Star Luxurious/Heritage Hotels/Preferred"],
    icon: "",
  },
  {
    label: "Included",
    value: [
      "Specilaized Bilingual Guide",
      "Private Transport",
      "Accomodation",
      "Complementary Drinks",
    ],
    icon: <FontAwesomeIcon icon={faCheckCircle} />,
  },
  {
    label: "Excluded",
    value: ["Additional Services", "Insurance", "Entry Fees", "Tickets"],
    icon: <FontAwesomeIcon icon={faTimesCircle} />,
  },
];
function TravelDetailsAbout({ travelDetails }) {
  return (
    <div className="">
      <div className="package-short-info row aic">
        {shortInfo.map((item, index) => {
          return (
            <div
              className="package-single-info col-lg-3 col-md-6 col-sm-6 section-background-1"
              key={index}
              elevation={5}
            >
              <p className="icon">{item.icon}</p>
              <div>
                <h6>{item.label}</h6>
                <strong>
                  {item.label === "Duration"
                    ? travelDetails?.duration
                    : item.label === "Starts From"
                    ? `$ ${travelDetails?.price}`
                    : item.value}
                </strong>
              </div>
            </div>
          );
        })}
        <p className="font-weight-semi py-3 font-italic">
          {travelDetails?.overview}
        </p>
      </div>

      <div className="top py-3">
        <h3 className="py-3">Highlights of our {travelDetails?.label}:</h3>

        <ul>
          {travelDetails?.highlights &&
            travelDetails.highlights.map((sub) => (
              <li className="py-1">
                <span className="text-success">**</span>{sub}
              </li>
            ))}
        </ul>

        <table className="mtb--20  plr-10">
          <tbody>
            {tableItems.map((item, index) => {
              return (
                <tr>
                  <td>
                    <div className="">{item.label}</div>
                  </td>
                  <td>
                    {item.value.map((values, index) => {
                      return (
                        <ul key={index}>
                          <li>
                            <span className="text-success ">{item.icon}</span>
                            {values === "" ? travelDetails?.title : values}
                          </li>
                        </ul>
                      );
                    })}
                  </td>
                </tr>
              );
            })}
            <tr>
              <td>
                <div className="m-20">
                  *Package Price exclusive of hotel prices.
                </div>
              </td>
              <td>
                <div className="m-20">
                  Give us your preferred choice of stay for a complete quote.
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="top py-3">
        <h3 className="py-3">
          Salient Features of our {travelDetails?.label}:
        </h3>

        <Feature large={3} />
      </div>
    </div>
  );
}

export default TravelDetailsAbout;
