import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TravelDetailsData from "../../consts/AllTourPackagesDetails.json";
import Helmet from "../../components/Helmet";
import Banner from "../../components/Banner";
import TravelDetailsAbout from "./_components/TravelDetailsAbout";
import TravelDetailsItinerary from "./_components/TravelDetailsItinerary";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import BookForm from "./_components/BookForm";
import { Col, Row } from "react-bootstrap";
import { Tab, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDesktop,
  faPhotoVideo,
} from "@fortawesome/free-solid-svg-icons";

function TravelDetails() {
  const [travelDetails, setTravelDetails] = useState({});

  const params = useParams();

  useEffect(() => {
    const id = params?.id;

    if (id) {
      getTourPackage(id);
    }
  }, [params]);

  const getTourPackage = (id) => {
    let item = TravelDetailsData.data.filter((a) => a.navigateTo === id);

    if (item[0]) {
      setTravelDetails(item[0]);
    }
  };

  return (
    <>
      <Helmet
        pageTitle={travelDetails?.metaTitle}
        description={travelDetails?.metaDescription}
        keywords={
          "Car Rentals | Golden Triangle Tours | Rajasthan Tours | Agra Tours | Sightseeing Tours"
        }
        pageUrl={window.location.href}
      />
      <Header />

      <Banner
        tourPackage={{
          title: travelDetails?.title,
          homeTitle: travelDetails?.label,
        }}
      />

      <div className="section-container">
        <Row>
          <Col lg={8} sm={12} md={8}>
            <Tab.Container className="mb-3 pb-4" defaultActiveKey="Overview">
              <Nav className="nav-tabs">
                <Nav.Item className="m-10">
                  <Nav.Link eventKey="Overview" className="m-2">
                    <span className="mlr-10">
                      <FontAwesomeIcon icon={faDesktop} />
                    </span>
                    Overview
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="m-10">
                  <Nav.Link eventKey="Itinerary" className="m-2">
                    <span className="mlr-10">
                      <FontAwesomeIcon icon={faPhotoVideo} />
                    </span>
                    Itinerary
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="Overview">
                  <TravelDetailsAbout travelDetails={travelDetails} />
                </Tab.Pane>

                <Tab.Pane eventKey="Itinerary">
                  <TravelDetailsItinerary travelDetails={travelDetails} />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
          <Col lg={4} sm={12} md={8}>
            <BookForm carRental={travelDetails} />
          </Col>
        </Row>
      </div>

      <Footer />
    </>
  );
}

export default TravelDetails;
