import TravelBlogsData from "../../consts/Blogs.json";

import Helmet from "../../components/Helmet";
import Banner from "../../components/Banner";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import BookForm from "./_components/BookForm";
import TravelBlogsAbout from "./_components/TravelBlogsAbout";

function TravelBlogs() {
  return (
    <>
      <Helmet
        pageTitle={"India Tour and Travel Blogs | North India Tours"}
        description={
          "India is a country with a rich tapestry of cultures and traditions, each region offering a unique experience."
        }
        keywords={
          "Car Rentals | Golden Triangle Tours | Rajasthan Tours | Agra Tours | Sightseeing Tours"
        }
        pageUrl={window.location.href}
      />
      <Header />

      <Banner
        tourPackage={{
          title: "Triangle",
          homeTitle: "Enquire India travel packages ",
        }}
      />

      <TravelBlogsAbout allBlogs={TravelBlogsData.data} />

      <BookForm />

      <Footer />
    </>
  );
}

export default TravelBlogs;
