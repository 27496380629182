import React from "react";
import { Col, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxesStacked,
  faCar,
  faUserCheck,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import CountUp from "react-countup";

const contentCard = [
  {
    title: "Excellent Trips Completed",
    icon: <FontAwesomeIcon icon={faCar} className="text-primary" />,
    value: 500,
  },
  {
    title: "Happy Families Served",
    icon: <FontAwesomeIcon icon={faUsers} className="text-primary" />,
    value: 2000,
  },
  {
    title: "Years of Service",
    icon: <FontAwesomeIcon icon={faUserCheck} className="text-primary" />,
    value: 10,
  },
  {
    title: "Customised Tour Packages",
    icon: <FontAwesomeIcon icon={faBoxesStacked} className="text-primary" />,
    value: 15,
  },
];

function Counter({ props }) {
  return (
    <Row>
      {contentCard.map((item) => (
        <Col md={props?.col || 6} className="mb-4">
          <div className="bg-white rounded-lg text-center py-2">
            <div className="icon text-center">{item?.icon}</div>
            <CountUp
              end={item?.value}
              className="fs-largest font-weight-medium"
            />
            <span className="fs-largest font-weight-medium">+</span>
            <p className="text-primary fs-18 font-weight-medium">
              {item?.title}
            </p>
          </div>
        </Col>
      ))}
    </Row>
  );
}

export default Counter;
