import React from "react";
import { Col, Row } from "react-bootstrap";
import FeatureCards from "../consts/FeatureConsts.json";

function Feature({large}) {
  return (
    <div className="card-section-2 py-5 gradient">
      {/* <div className="section-container"> */}
        <Row className=" justify-content-center">
          {FeatureCards.data.descriptionTags.map((item, index) => (
            <Col
              sm={12}
              md={large || 2}
              lg={large || 2}
              className={`mb-3 mlr-20 card border rounded-sm d-flex justify-content-center align-items-center flex-column p-3 shadow-lg text-center section-background-${
                index + 1
              }`}
              key={index}
            >
              <img
                src={item?.icon}
                className="text-danger border rounded-circle"
                alt={"item"}
              />
              <h4 className="mt-2">{item?.subTitle}</h4>
              <p className="text-white">{item?.description}</p>
            </Col>
          ))}
        </Row>
      {/* </div> */}
    </div>
  );
}

export default Feature;
