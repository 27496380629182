/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect } from "react";
import { SocialIcons } from "../consts/SocialIconsConst";

export default (props) => {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <div className="footer p-5">
      <div className="section-container">
        <div className="row mx-0">
          <div className="col-lg-4 col-md-6 col-12 px-0">
            <div className="footer-link text-white">
              <h3>Rudra Holidays India</h3>
              <div id="google_translate_element"></div>
              <ul className="ft-link text-white mt-3">
                <li className="mb-4">
                  <a className="text-white" href="/contact">
                    Contact
                  </a>
                </li>
                <li className="mb-4">
                  <a className="text-white" href="/travel-blogs">
                    Blog
                  </a>
                </li>
                <li className="mb-4">
                  <a className="text-white" href="/customer-reviews">
                    Testimonials
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 px-0">
            <div className="footer-link text-white">
              <h3>Services</h3>
              <ul className="ft-link text-white mt-3">
                <li className="mb-4">
                  <a
                    className="text-white"
                    href="/curated-tour-packages/golden-triangle-tours"
                  >
                    Golden Triangle Tours
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    className="text-white"
                    href="/car-rentals/luxurious-suvs-rentals"
                  >
                    Luxurious SUVs
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    className="text-white"
                    href="/curated-tour-packages/sightseeing-tours"
                  >
                    Sightseeing Tours
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 px-0">
            <div className="footer-link text-white mt-4 mt-lg-0">
              <h3>Say Hello</h3>
              <ul className="ft-link mt-3">
                <li className="mb-4">
                  <a
                    href="mailto:info@rudraholidaysindia.com"
                    className="text-white"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Email: info@rudraholidaysindia.com
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    href="tel:+917289974763"
                    className="text-white"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Tel: +91 7289974763
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    href="https://maps.app.goo.gl/oFz9LJf9Jno6mNTw8"
                    className="text-white"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Address: Pocket 6, Sunrise Apartment, Dwarka, Delhi - 110045
                  </a>
                </li>
              </ul>
              <div className="text-white d-flex align-items-centern">
                {SocialIcons.map((item) => (
                  <a
                    className="mr-2"
                    href={item?.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item?.Social}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
        <p className="text-muted mt-5">
          Copyright © 2023 Rudra Holidays India. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};
