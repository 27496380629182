import React from "react";
import PackageCardDetails from "../../../components/PackageCardDetails";
import { Col, Row } from "react-bootstrap";

function Packages({ allTourPackages }) {

  return (
    <div className={`section-container `}>
      <div className="top ">
        <h2 className="py-3">Browse and customise your Tours:</h2>

        <div className="package-card-section">
          <div className="package-card-wrapper">
            <Row>
              {allTourPackages.map((tourPackage) => (
                <Col lg={3} sm={12} md={3}>
                  <PackageCardDetails tourPackage={tourPackage} />
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Packages;
