import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import TourPackageDetails from "./pages/TourDetails";
import TravelBlogs from "./pages/TravelBlogs";
import CarRentals from "./pages/CarRentals";
import Testimonials from "./pages/Testimonials";
import TourPackages from "./pages/Tours";
import TravelBlogDetails from "./pages/TravelBlogDetails";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/travel-blogs" element={<TravelBlogs />} />
          <Route
            path="/travel-blog-details/:id"
            element={<TravelBlogDetails />}
          />
          <Route path="/car-rentals/:id" element={<CarRentals />} />
          <Route path="/customer-reviews" element={<Testimonials />} />
          <Route
            path="/tour-package-details/:id"
            element={<TourPackageDetails />}
          />
          <Route path="/curated-tour-packages/:id" element={<TourPackages />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
