import React from "react";

function CarRentalAbout({ carRental }) {
  return (
    <div className={`section-container `}>
      <div className="top py-3">
        <h2 className="py-3">{carRental?.subtitle}</h2>
        <p className="fs-18 py-3">{carRental?.details}</p>
      </div>

      <div className="top py-3">
        <h2 className="py-3">Highlights of our {carRental?.title} Rentals:</h2>

        <ul>
          {carRental?.subDetails &&
            carRental.subDetails.map((sub) => (
              <li>
                <h4>{sub?.title}</h4>
                <p>{sub?.description}</p>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default CarRentalAbout;
