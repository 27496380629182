import React from "react";
import { Card } from "react-bootstrap";

function TravelBlogsCards({ blog }) {
  return (
    <Card className="border-0 shadow-5">
      <Card.Img
        variant="top"
        src={`/assets/images/thumbnail/${blog?.tbImage}`}
        className="blog-card-img p-2"
      />
      <Card.Body className="text-center pt-0">
        <p className="fs-smallest text-muted font-weight-medium">
          {blog?.shortInfo[0]}, {blog?.shortInfo[1]}
        </p>
        <Card.Title className="fs-18 font-weight-semi mt-4">
          {blog?.name}
        </Card.Title>
        <Card.Text className="text-muted">{blog?.details}</Card.Text>
        <a
          variant=""
          className="btn btn-dark mt-3"
          href={`/travel-blog-details/${blog?.navigateTo}`}
        >
          Read More
        </a>
      </Card.Body>
    </Card>
  );
}

export default TravelBlogsCards;
