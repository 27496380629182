import React from "react";
import parse from 'html-react-parser';

function BlogDetailAbout({ blogDetail }) {
  return (
    <div className={`section-container `}>
      <div className="top py-3">
        {console.log(blogDetail?.details)}
        {blogDetail?.details && parse(blogDetail?.details)}
      </div>
    </div>
  );
}

export default BlogDetailAbout;
