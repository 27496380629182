import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function PackageCardDetails({ tourPackage }) {
  return (
    <div className="package-card ">
      <div className=" position-relative pb-5">
        <div className="overlay-2">
          {" "}
          <img
            src={`/assets/images/thumbnail/${tourPackage?.image}`}
            className="shadow-1 0verlay-2 w-100"
            alt="package-title"
          />
        </div>

        <span className="tag-price text-white rounded-sm px-3 position-absolute py-2">
          ${tourPackage?.price}
        </span>

        <span className="tag text-white rounded-sm px-4 position-absolute py-2">
          <FontAwesomeIcon icon={faBriefcase} className="mr-2" />
          Luxurious
        </span>
        <div className="content bg-white radius-10 p-3 position-absolute shadow-sm text-white">
          <h3 className="">{tourPackage?.label}</h3>
          <p className=" fs-smallest mb-3">{tourPackage?.details}</p>

          <a
            className="btn btn-dark"
            href={`/tour-package-details/${tourPackage?.navigateTo}`}
          >
            Cuctomise Tour
          </a>
        </div>
      </div>
    </div>
  );
}

export default PackageCardDetails;
