import React from "react";

function PackageAbout({ tourPackage }) {
  return (
    <div className={`section-container `}>
      <div className="top py-3">
        <h2 className="py-3">{tourPackage?.subtitle}</h2>
        <p className="fs-18 py-3">{tourPackage?.details}</p>
      </div>

      <div className="top py-3">
        <h2 className="py-3">Highlights of our {tourPackage?.title} Tours:</h2>

        <ul>
          {tourPackage?.subDetails &&
            tourPackage.subDetails.map((sub) => (
              <li>
                <h4>{sub?.heading}</h4>
                <p>{sub?.description}</p>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default PackageAbout;
