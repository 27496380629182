import React from "react";
import Feature from "../../../components/Feature";

function PackageHighlights({ tourPackage }) {
  return (
    <div className={`section-container `}>
      <div className="top py-3">
        <h2 className="py-3">
          Salient Features of our {tourPackage?.title} Tours:
        </h2>
        <Feature />
      </div>
    </div>
  );
}

export default PackageHighlights;
