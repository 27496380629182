import {
  faFacebookF,
  faGoogle,
  faInstagram,
  faPinterest,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faTriangleCircleSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const SocialIcons = [
  {
    Social: <FontAwesomeIcon icon={faFacebookF} className="text-primary fs-24" />,
    link: "https://www.facebook.com/profile.php?id=61553591667812",
  },
  {
    Social: <FontAwesomeIcon icon={faInstagram} className="text-primary fs-24" />,
    link: "https://www.instagram.com/rudraholidaysindia2010/",
  },
  {
    Social: <FontAwesomeIcon icon={faTwitter} className="text-primary fs-24" />,
    link: "https://twitter.com/rudraholidays10",
  },
  {
    Social: <FontAwesomeIcon icon={faYoutube} className="text-primary fs-24" />,
    link: "https://www.youtube.com/channel/UCyeWqsHFsDQhN3p-Snj1F-w",
  },
  {
    Social: (
      <FontAwesomeIcon
        icon={faTriangleCircleSquare}
        className="text-primary fs-24"
      />
    ),
    link: " ",
  },
  {
    Social: <FontAwesomeIcon icon={faGoogle} className="text-primary fs-24" />,
    link: " ",
  },
  {
    Social: <FontAwesomeIcon icon={faPinterest} className="text-primary fs-24" />,
    link: "https://in.pinterest.com/rudraholiday/",
  },
];
