import React from "react";
import { Col, Row } from "react-bootstrap";
import TestimonialsCardDetails from "../../../components/TestimonialsCardDetails";
import QRCode from "react-qr-code";

function Testimonials({ allTestimonials }) {
  return (
    <div className="section-background-1">
      <div className={`section-container `}>
        <div className="top py-3">
          <h2 className="py-3">
            In Their Own Words: Customer Testimonials Corner
          </h2>
          <p className="fs-18 py-3">
            Explore our testimonial page and embark on a journey through the
            authentic experiences of our customers. We are immensely proud of
            the impact we've made, and we invite you to join the ranks of those
            who have found success, satisfaction, and inspiration through our
            products or services.
          </p>
        </div>

        <div className="testimonial-section-2 mx-auto py-5">
          <Row>
            {allTestimonials.map((item) => (
              <Col md={4} lg={4} className="mb-5 mb-md-0 mt-5">
                <TestimonialsCardDetails testimonial={item} />
              </Col>
            ))}

            <Col
              md={4}
              lg={4}
              className="mb-5 mb-md-0 mt-5"
              style={{
                height: "auto",
                margin: "0 auto",
                // maxWidth: 64,
                width: "100%",
              }}
            >
              <p>Scan QR to write a review on Google:</p>
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={"https://rudraholidaysindia.com/contact"}
                viewBox={`0 0 256 256`}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
