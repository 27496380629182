/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { Helmet } from "react-helmet";

export default function (props) {
  return (
    <Helmet>
      <title>{props?.pageTitle}</title>
      <meta charSet="utf-8" />
      <meta name="description" content={props?.description} />
      <meta name="keywords" content={props?.keywords} />
      <link rel="canonical" href={props?.pageUrl} />
      <meta name="robots" content="all" />
      <meta name="googlebot" content="all" />
    </Helmet>
  );
}
