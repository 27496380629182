import React from "react";
import Counter from "../../../components/Counter";

function Section4() {
  return (
    <section className="section-background-6 py-5">
      <div className="about-area">
        <div className="about-wrapper">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 pr-5">
                <div className="about-inner inner ">
                  <h2 className="title text-white">
                    Why choose Rudra Holidays India!!
                  </h2>
                  <p className="description  text-white">
                    Select <strong>Rudra Holidays India</strong>, the{" "}
                    <strong>premier travel agency in Delhi</strong>, for an
                    outstanding travel adventure. With over 15+ years of
                    experience, we provide remarkable
                    <strong>
                      {" "}
                      Golden Triangle, lively Rajasthan, and captivating South
                      India tours
                    </strong>
                    . Our extensive services accommodate a wide range of travel
                    requirements. As <strong>LGBTQ+- friendly </strong>tour
                    operators, we prioritize inclusivity, ensuring that all
                    individuals are warmly welcomed to explore India with us and
                    create lasting memories.
                  </p>
                  <a
                    href="/contact"
                    className="btn active btn-dark btn-lg mt-3 mb-3"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
              <div className="counterup-area col-lg-6">
                <Counter />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section4;
