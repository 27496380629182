import React from "react";

function BookForm() {
  return (
    <section>
      <div className="top-banner-2">
        <div className="overlay-2 text-white d-flex align-items-center">
          <div className="section-container">
            <div className="content">
              <h2 className="fs-largest mb-4">
                Luxurious Golden Triangle Tour Packages
              </h2>
              <p className="mb-5 text-grey">
                Discover the regal charm and vibrant tapestry of Northern India
                with our meticulously curated travel packages. Whether you're
                captivated by the historic forts, enchanted by colorful
                festivals, or yearning for the taste of authentic Rajasthani
                cuisine, our packages are designed to immerse you in the
                grandeur of this royal state.
              </p>
            </div>
            <a
              className="btn btn-dark text-uppercase mr-3 radius-0 py-3 px-5"
              href="/curated-tour-packages/golden-triangle-tours"
            >
              Explore
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BookForm;
