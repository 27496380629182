import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { Button, Form } from "react-bootstrap";


function BookForm({ carRental }) {
  const [result, showresult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "rudraholidaysindia",
        "template_8sgdzlp",
        e.target,
        "0R9SNdPi-YhCyfnuC"
      )
      .then(
        (result) => {
          showresult(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showresult(true);
  };

  return (
    <div className={`section-container `}>
      <div className="top ">
        <h2 className="py-3">
          {carRental?.title} Rentals starting from just{" "}
          <span className="text-primary">${carRental?.price}</span>. Get
          customised quote now!
        </h2>

        <div className="form-section-2 p-5">
          <Form onSubmit={sendEmail}>
            <Form.Group>
              <Form.Label className="fs-smallest font-weight-medium text-dark">
                Name
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Your name"
                className="mb-4 py-4 bg-transparent shadow-sm"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="fs-smallest font-weight-medium text-dark">
                Email
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="you@company.com"
                className="mb-4 py-4 bg-transparent shadow-sm"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="fs-smallest font-weight-medium text-dark">
                Start Date
              </Form.Label>
              <Form.Control
                type="date"
                placeholder="date"
                className="mb-4 py-4 bg-transparent shadow-sm"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="fs-smallest font-weight-medium text-dark">
                End Date
              </Form.Label>
              <Form.Control
                type="date"
                placeholder="date"
                className="mb-4 py-4 bg-transparent shadow-sm"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="fs-smallest font-weight-medium text-dark">
                Interested in
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Rental Type"
                className="mb-4 py-4 bg-transparent shadow-sm"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="fs-smallest font-weight-medium text-dark">
                Message for us!
              </Form.Label>
              <Form.Control
                type="textarea"
                placeholder="Tell us a little about the tour..."
                className="mb-4 py-4 bg-transparent shadow-sm"
              />
            </Form.Group>

            <Button variant="dark" type="submit" className="w-100 mt-4">
              Get Quote
            </Button>
          </Form>
          <div className="rn-form-group">
            {result ? (
              <p className="text-success">
                Your Message has been successfully sent. We will contact you
                soon.
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookForm;
