import Header from "../../components/Header";

import Footer from "../../components/Footer";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCab,
  faTrain,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import Section4 from "../Home/_components/Section4";
import { SocialIcons } from "../../consts/SocialIconsConst";
import Helmet from "../../components/Helmet";

import React, { useState } from "react";
import emailjs from "@emailjs/browser";

const serviceConst = [
  {
    title: "Luxurious Tour Packages",
    details:
      "Customised and Luxurious Tour packages for you. Our tour package consists of luxurious rides with complementary drinks and most luxurious stays.",
    icon: <FontAwesomeIcon icon={faTrain} className="icon mr-3" />,
  },
  {
    title: "Luxurious Car Rentals",
    details:
      "Our car rentals redefine your travel experience, offering a perfect blend of style, comfort, and performance. Hassle free rides all along your tour.",
    icon: <FontAwesomeIcon icon={faCab} className="icon mr-3" />,
  },
  {
    title: "Expert Tour Guide",
    details:
      "Our tour guides have over 30 years of tour experience. All our tour guides are fluent in English, Spanish and French offering unique experiences.",
    icon: <FontAwesomeIcon icon={faUserShield} className="icon mr-3" />,
  },
];

const Contact = () => {
  const [result, showresult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "rudraholidaysindia",
        "template_8sgdzlp",
        e.target,
        "0R9SNdPi-YhCyfnuC"
      )
      .then(
        (result) => {
          showresult(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showresult(true);
  };
  return (
    <>
      <Helmet
        pageTitle={"Contact for Best India Travel Packages, Car Rentals"}
        description={
          "Contact us for best offers on India Tours with exclusive benefits. Get upto 20% off on Car Rentals."
        }
        keywords={
          "Car Rentals | Golden Triangle Tours | Rajasthan Tours | Agra Tours | Sightseeing Tours"
        }
        pageUrl={window.location.href}
      />
      <Header />
      <div className="top-banner-contact mb-5">
        <div className="overlay  d-flex flex-column justify-content-center align-items-center text-white">
          <h2 className="text-center">
            <span className="text-warning">Contact</span> us for{" "}
            <span className="text-warning">India Tours</span>
          </h2>
          <div className="d-flex flex-row justify-content-center align-items-center my-5">
            {SocialIcons.map((item) => (
              <div className="mb-3 mlr-10">
                <a href={item?.link} target="_blank" rel="noreferrer">
                  {item?.Social}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="form-section bg-grey p-3  p-md-5">
        <div className="inner m-md-4 p-3 p-md-5">
          <Row>
            <Col md={4} className="mb-4 order-2 order-md-1">
              <h2 className="font-weight-semi">Get a quote</h2>
              <p className="my-4">
                {" "}
                Start customising your India Tour. Leave us your suggestions and
                some basic information for us to come up with the best tour
                packages for you{" "}
              </p>
              <Form onSubmit={sendEmail}>
                <Form.Group className="mb-4  shadow-sm">
                  <Form.Control
                    type="text"
                    placeholder="Your Name"
                    className="radius-0 py-4"
                  />
                </Form.Group>

                <Form.Group className="mb-4  shadow-sm">
                  <Form.Control
                    type="email"
                    placeholder="Your email"
                    className="radius-0 py-4"
                  />
                </Form.Group>
                <Form.Group className="mb-4  shadow-sm">
                  <Form.Control
                    type="date"
                    placeholder="Travel Date"
                    className="radius-0 py-4"
                  />
                </Form.Group>
                <Form.Group className="mb-4  shadow-sm">
                  <Form.Control
                    type="text"
                    placeholder="Hotel Type"
                    className="radius-0 py-4"
                  />
                </Form.Group>
                <Form.Group className="mb-4  shadow-sm">
                  <Form.Control
                    type="textarea"
                    placeholder="Describe your requirements?"
                    className="radius-0 py-4"
                  />
                </Form.Group>
                <div className="text-right">
                  <Button
                    className="btn btn-dark radius-0 px-5 mt-3"
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
              <div className="rn-form-group">
                {result ? (
                  <p className="text-success">
                    Your Message has been successfully sent. We will contact you
                    soon.
                  </p>
                ) : null}
              </div>
            </Col>
            <Col md={8} className="mb-4 pl-md-5 order-1 order-md-2">
              <img
                src="/assets/images/mail.jpg"
                className="right-img ml-auto w-100"
                alt="contact"
              />
            </Col>
          </Row>
        </div>
      </div>

      <div className="service-section py-5  section-background-1">
        <div className="section-container">
          <h2 className="text-center mb-5">Our Services</h2>
          <Row>
            {serviceConst.map((item) => (
              <Col md={4} className="mb-4">
                <div className="d-flex">
                  {item?.icon}
                  <div>
                    <h3 className="text-white">{item?.title}</h3>
                    <p className="text-dark">{item?.details}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>

      <div className="top-banner-2">
        <div className="overlay-2 text-white d-flex align-items-center">
          <div className="section-container">
            <div className="content">
              <h2 className="fs-largest mb-4">
                Luxurious Rajasthan Travel Packages
              </h2>
              <p className="mb-5 text-grey">
                Discover the regal charm and vibrant tapestry of Rajasthan with
                our meticulously curated travel packages. Whether you're
                captivated by the historic forts, enchanted by colorful
                festivals, or yearning for the taste of authentic Rajasthani
                cuisine, our packages are designed to immerse you in the
                grandeur of this royal state.
              </p>
            </div>
            <a
              className="btn btn-dark text-uppercase mr-3 radius-0 py-3 px-5"
              href="/curated-tour-packages/rajasthan-tours"
            >
              Explore
            </a>
          </div>
        </div>
      </div>

      <Section4 />

      <Footer />
    </>
  );
};

export default Contact;
