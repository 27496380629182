import React from "react";

function Banner({ tourPackage }) {
  return (
    <div className={`top-banner-${tourPackage?.title}`}>
      <div className="overlay  d-flex flex-column justify-content-center align-items-center text-white">
        <h2 className="text-center py-3">{tourPackage?.homeTitle}</h2>
        <div className="banner-form d-flex flex-wrap align-items-center justify-content-center p-3 mt-4">
          <div className="form-group mb-2 mr-3">
            <label
              htmlFor=""
              className="font-weight-medium fs-salles text-grey"
            >
              Name
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Your Name"
              name="name"
            />
          </div>
          <div className="form-group mb-2 mr-3">
            <label
              htmlFor=""
              className="font-weight-medium fs-salles text-grey"
            >
              Email
            </label>
            <input
              type="email"
              className="form-control"
              placeholder="Your Email"
              name="email"
            />
          </div>
          <div className="form-group mb-2 mr-3">
            <label
              htmlFor=""
              className="font-weight-medium fs-salles text-grey"
            >
              Interested
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Interested In"
              name="package"
            />
          </div>
          <div className="form-group mb-2 mr-3">
            <label
              htmlFor=""
              className="font-weight-medium fs-salles text-grey"
            >
              Start Date
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Start Date"
              name="start-date"
            />
          </div>
        </div>
        <div className="form-group mb-2">
          <button className="btn btn-dark btn-lg">Enquire</button>
        </div>
      </div>
    </div>
  );
}

export default Banner;
