import React from "react";
import { Col, Row } from "react-bootstrap";
import TravelBlogsCards from "../../../components/TravelBlogsCards";

function TravelBlogsAbout({ allBlogs }) {
  return (
    <div className={`section-container `}>
      <div className="top py-3">
        <h2 className="py-3">
          India Tour and Travel Blogs - Indulge in the mesmerising beauty of
          India!
        </h2>
        <p className="fs-18 py-3">
          India is a vast and diverse country with a rich cultural heritage,
          stunning landscapes, and a myriad of experiences to offer. Planning a
          tour and travel in India can be an exciting adventure. Our travel
          blogs are a small attempt to make your travel journey hassle free and
          more enjoyable.
        </p>
      </div>

      <div className="blog-section">
          <Row>
            {allBlogs.map((item) => (
              <Col md={4} lg={4}>
                <TravelBlogsCards blog={item}/>
              </Col>
            ))}
          </Row>
        </div>
    </div>
  );
}

export default TravelBlogsAbout;
