import React from "react";
import Feature from "../../../components/Feature";
import { Col, Row } from "react-bootstrap";

function CarRentalHighlights({ carRental }) {
  return (
    <div className={`section-container `}>
      <div className="top py-3">
        <h2 className="py-3">
          Salient Features of our {carRental?.title} Rentals:
        </h2>

        <Row>
          {carRental?.highlights &&
            carRental.highlights.map((item) => (
              <Col sm={6} md={4} className="py-4 px-4 mb-2">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="short-info text-center text-white">
                    <h5>{item}</h5>
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </div>
      <div className="top py-3">
        <h2 className="py-3">
          Why book with {carRental?.title} Rentals Rudra Holidays India:
        </h2>

        <Feature />
      </div>
    </div>
  );
}

export default CarRentalHighlights;
