import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function TestimonialsCardDetails({ testimonial }) {
  return (
    <div className="border rounded-lg shadow-sm p-4">
      <img
        src={testimonial?.image}
        className="rounded-pill mr-5"
        alt="user-testimonials"
      />
      <h3 className="my-4 pt-4">{testimonial?.title}</h3>
      <p className="fs-smallest font-italic mb-3">
        {testimonial?.details}
      </p>
      <div className="border-top pt-3 d-flex justify-content-between align-items-center">
        <div className="left">
          <p className="text-dark font-weight-bold mb-0">{testimonial?.name}</p>
        </div>
        <div className="right">
          <FontAwesomeIcon icon={faStar} className="text-primary ml-2" />
          <FontAwesomeIcon icon={faStar} className="text-primary" />
          <FontAwesomeIcon icon={faStar} className="text-primary" />
          <FontAwesomeIcon icon={faStar} className="text-primary" />
          <FontAwesomeIcon icon={faStar} className="text-primary" />
        </div>
      </div>
    </div>
  );
}

export default TestimonialsCardDetails;
