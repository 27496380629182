/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const headerItems = [
  {
    label: "Home",
    navigateTo: "/",
    hasDropDown: false,
  },
  {
    label: "Luxury Tours",
    navigateTo: null,
    hasDropDown: true,
    subMenu: [
      {
        label: "Sightseeing Tours",
        navigateTo: "/curated-tour-packages/sightseeing-tours",
      },
      {
        label: "Featured Tours",
        navigateTo: "/curated-tour-packages/featured-tours",
      },
      {
        label: "Golden Triangle Tours",
        navigateTo: "/curated-tour-packages/golden-triangle-tours",
      },
      {
        label: "Rajasthan Tours",
        navigateTo: "/curated-tour-packages/rajasthan-tours",
      },
    ],
  },
  {
    label: "Car Rentals",
    navigateTo: null,
    hasDropDown: true,
    subMenu: [
      {
        label: "Premium Sedans",
        navigateTo: "/car-rentals/premium-sedans-rentals",
      },
      {
        label: "Luxurious SUVs",
        navigateTo: "/car-rentals/luxurious-suvs-rentals",
      },
      {
        label: "Van and Coaches",
        navigateTo: "/car-rentals/van-and-coaches-rentals",
      },
    ],
  },
  {
    label: "Blogs",
    navigateTo: "/travel-blogs",
    hasDropDown: false,
  },

  {
    label: "Reviews",
    navigateTo: "/customer-reviews",
    hasDropDown: false,
  },

  {
    label: "Contact",
    navigateTo: "/contact",
    hasDropDown: false,
  },
];

export default (props) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const menuOpenHandler = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="px-2">
        <div className="header-wrapper d-flex justify-content-between align-items-center">
          <div className="logo display-inline-block my-auto">
            <a href="/">
              <img src="/assets/images/logo.webp" alt="logo" />
            </a>
          </div>
          <div className="left d-flex align-items-center">
            <div className={`menu ${menuOpen ? "active" : ""}`}>
              <button
                className="menu-close bg-light text-dark bg-transparent border-0 position-absolute d-md-none"
                onClick={menuOpenHandler}
              >
                X
              </button>
              <ul className="d-flex justify-content-md-between align-items-center mb-0">
                <ul className="d-flex justify-content-md-between align-items-center mb-0">
                  {headerItems.map((item) => (
                    <li className={`mx-2 px-2 py-4`}>
                      {item?.hasDropDown === false ? (
                        <a href={item?.navigateTo} className="mb-0 text-dark">
                          {item?.label}
                        </a>
                      ) : (
                        <Dropdown>
                          <Dropdown.Toggle variant="" id="dropdown-basic">
                            {item?.label}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {item?.subMenu &&
                              item.subMenu.map((submen) => (
                                <Dropdown.Item href={submen?.navigateTo}>
                                  {submen?.label}
                                </Dropdown.Item>
                              ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </li>
                  ))}
                </ul>
              </ul>
            </div>
            <div className="right d-flex align-items-center postion-relative">
              <a
                className="btn btn-outline-primary ml-3 py-lg-2 px-lg-3 d-flex align-items-center"
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/917289974763"
              >
                WhatsApp
              </a>
            </div>

            <FontAwesomeIcon
              icon={faBars}
              className="menu-open d-inline-block d-md-none ml-3 cursor-pointer "
              onClick={menuOpenHandler}
            />
          </div>
        </div>
      </div>
    </header>
  );
};
