import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BlogDetailsData from "../../consts/BlogContents.json";

import Helmet from "../../components/Helmet";
import Banner from "../../components/Banner";
import BlogDetailsAbout from "./_components/BlogDetailsAbout";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import BookForm from "./_components/BookForm";

function BlogDetails() {
  const [blogDetail, setBlogDetail] = useState({});

  const params = useParams();

  useEffect(() => {
    const id = params?.id;

    if (id) {
      getTourPackage(id);
    }
  }, [params]);

  const getTourPackage = (id) => {
    let item = BlogDetailsData.data.filter((a) => a.navigateTo === id);

    if (item[0]) {
      setBlogDetail(item[0]);
    }
  };

  return (
    <>
      <Helmet
        pageTitle={blogDetail?.name}
        description={blogDetail?.metaDescription}
        keywords={
          "Car Rentals | Golden Triangle Tours | Rajasthan Tours | Agra Tours | Sightseeing Tours"
        }
        pageUrl={window.location.href}
      />
      <Header />

      <Banner
        tourPackage={{
          title: blogDetail?.title,
          homeTitle: "Get free quote for your next India Tour",
        }}
      />

      <BlogDetailsAbout blogDetail={blogDetail} />

      <BookForm blogDetail={blogDetail} />

      <Footer />
    </>
  );
}

export default BlogDetails;
