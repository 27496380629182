import Header from "../../components/Header";

import Footer from "../../components/Footer";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TourPackagesData from "../../consts/TourPackages.json";
import AllTourPackages from "../../consts/AllTourPackages.json";

import Helmet from "../../components/Helmet";
import Banner from "../../components/Banner";
import PackageAbout from "./_components/PackageAbout";
import PackageHighlights from "./_components/PackageHighlights";
import Packages from "./_components/Packages";

function TourPackage() {
  const [tourPackage, setTourPackage] = useState({});
  const [allTourPackages, setAllTourPackages] = useState([]);

  const params = useParams();

  useEffect(() => {
    const id = params?.id;

    if (id) {
      getTourPackage(id);
    }
  }, [params]);

  const getTourPackage = (id) => {
    let item = TourPackagesData.data.filter((a) => a.navigateTo === id);

    if (item[0]) {
      setTourPackage(item[0]);

      let title = item[0]?.title;

      if (item[0]?.title === "Triangle") title = "Golden Triangle";

      let allTours = AllTourPackages.data.filter((tour) =>
        tour.category.includes(title)
      );

      if (allTours.length) {
        setAllTourPackages(allTours);
      }
    }
  };

  return (
    <>
      <Helmet
        pageTitle={tourPackage?.metaTitle}
        description={tourPackage?.metaDescription}
        keywords={
          "Car Rentals | Golden Triangle Tours | Rajasthan Tours | Agra Tours | Sightseeing Tours"
        }
        pageUrl={window.location.href}
      />
      <Header />

      <Banner tourPackage={tourPackage} />

      <PackageAbout tourPackage={tourPackage} />

      <PackageHighlights tourPackage={tourPackage} />

      <Packages allTourPackages={allTourPackages} />

      <Footer />
    </>
  );
}

export default TourPackage;
