import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CarRentalsData from "../../consts/CarRentals.json";

import Helmet from "../../components/Helmet";
import Banner from "../../components/Banner";
import CarRentalAbout from "./_components/CarRentalAbout";
import CarRentalHighlights from "./_components/CarRentalHighlights";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import BookForm from "./_components/BookForm";

function CarRentals() {
  const [carRental, setCarRental] = useState({});

  const params = useParams();

  useEffect(() => {
    const id = params?.id;

    if (id) {
      getTourPackage(id);
    }
  }, [params]);

  const getTourPackage = (id) => {
    let item = CarRentalsData.data.filter((a) => a.navigateTo === id);

    if (item[0]) {
      setCarRental(item[0]);

      
    }
  };

  return (
    <>
      <Helmet
        pageTitle={carRental?.metaTitle}
        description={carRental?.metaDescription}
        keywords={
          "Car Rentals | Golden Triangle Tours | Rajasthan Tours | Agra Tours | Sightseeing Tours"
        }
        pageUrl={window.location.href}
      />
      <Header />

      <Banner tourPackage={carRental} />

      <CarRentalAbout carRental={carRental} />

      <CarRentalHighlights carRental={carRental} />

      <BookForm carRental={carRental}/>

      <Footer />
    </>
  );
}

export default CarRentals;
