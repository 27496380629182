import TestimonialsData from "../../consts/Testimonials.json";

import Helmet from "../../components/Helmet";
import Banner from "../../components/Banner";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import BookForm from "./_components/BookForm";
import TestimonialsAbout from "./_components/TestimonialsAbout";

function Testimonials() {
  return (
    <>
      <Helmet
        pageTitle={"Customer Testimonials | Rudra Holidays India Reviews"}
        description={
          "Customer reviews - An authentic and transparent experience of our travellers."
        }
        keywords={
          "Car Rentals | Golden Triangle Tours | Rajasthan Tours | Agra Tours | Sightseeing Tours"
        }
        pageUrl={window.location.href}
      />
      <Header />

      <Banner
        tourPackage={{
          title: "Rajasthan",
          homeTitle: "Enquire India travel packages ",
        }}
      />

      <TestimonialsAbout allTestimonials={TestimonialsData.data} />

      

      <BookForm />

      <Footer />
    </>
  );
}

export default Testimonials;
