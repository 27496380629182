import Header from "../../components/Header";
import Footer from "../../components/Footer";
import React, { Suspense, lazy } from "react";
import Loader from "../../components/Loader";
import Helmet from "../../components/Helmet";

const Section1 = lazy(() => import("./_components/Section1"));
const Section2 = lazy(() => import("./_components/Section2"));
const Section3 = lazy(() => import("./_components/Section3"));
const Section4 = lazy(() => import("./_components/Section4"));
const Section5 = lazy(() => import("./_components/Section5"));
const Section6 = lazy(() => import("./_components/Section6"));

const Home = () => {
 
   
  return (
    <>
      <Helmet
        pageTitle={"Best India Travel Packages | Featured India Tours"}
        description={
          "Explore India with comfort and luxury. Get best offers on India Tours with exclusive benefits."
        }
        keywords={
          "Car Rentals | Golden Triangle Tours | Rajasthan Tours | Agra Tours | Sightseeing Tours"
        }
        pageUrl={window.location.href}
      />
      <Header />
      <div className="home-page">
        <Suspense fallback={<Loader />}>
          <Section1 />
        </Suspense>
        <Suspense fallback={<div></div>}>
          <Section2 />
        </Suspense>
        <Suspense fallback={<div></div>}>
          <Section3 />
        </Suspense>
        <Suspense fallback={<div></div>}>
          <Section4 />
        </Suspense>
        <Suspense fallback={<div></div>}>
          <Section5 />
        </Suspense>
        <Suspense fallback={<div></div>}>
          <Section6 />
        </Suspense>
      </div>
      <Footer />
    </>
  );
};

export default Home;
