import React from "react";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

function TravelDetailsItinerary({ travelDetails }) {
  return (
    <div className="mlr-20 mtb--20">
      <ul className="p-timeline">
        {travelDetails.travelPlan === undefined
          ? null
          : travelDetails.travelPlan.map((travelPlan, index) => {
              return (
                <li>
                  <div className="d-flex align-items-center">
                    <h5 className="px-3 py-2 rounded-circle section-background-3">{index + 1}</h5>
                    <h5 className="mx-2">{travelPlan.heading}</h5>
                  </div>
                  <div className="timeline-content">
                    <strong>{travelPlan.timings}</strong>
                    <div className="mtb--20">{parse(travelPlan?.details)}</div>
                    <ul className="py-2 font-weight-semi">
                      <li>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-success mlr-10"
                        />
                        Specilaized Bilingual Guide
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="text-success mlr-10"
                        />
                        Private Transport
                      </li>
                    </ul>
                  </div>
                </li>
              );
            })}
      </ul>
    </div>
  );
}

export default TravelDetailsItinerary;
